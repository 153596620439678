.Home {
  min-height: 100vh;
  min-width: 100vw;
  background-color: rgb(239, 238, 236);
  background-image: url(/src/images/janky.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.Home-body {
  height: 100%;
  background-color: transparent;
}

.Home-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 80vh;
  max-width: 80vw;
}

.Home-bio-h1 {
  font-family: 'avenir black', sans-serif;
  color: black;
  font-size: 48px;
  text-align: center;
}

.Home-bio-p {
  font-family: 'avenir', sans-serif;
  font-size: larger;
  padding: 2em 3em;
  text-align: justify;
}

@media screen and (min-width: 600px) {
  .Home-bio-p {
    font-family: 'avenir', sans-serif;
    font-size: larger;
    padding: 2em 6em;
    text-align: justify;
  }
}

@media screen and (max-width: 500px) {
  .Home {
    background-position-y: 15%;
  }
}