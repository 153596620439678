.FAQ {
  min-height: 100vh;
  min-width: 100vw;
  background-color: rgb(239, 238, 236);
}

.FAQ-h {
  font-family: 'avenir', Helvetica, sans-serif;
  text-align: center;
  font-size: 48px;
  padding-bottom: 0;
}

.FAQ-a,
.FAQ-q {
  font-family: 'avenir', Helvetica, sans-serif;
}

.FAQ-wrap {
  font-family: 'avenir', Helvetica, sans-serif;
  padding: 1em 2em;
}

.FAQ-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 80vh;
  max-width: 80vw;
  margin-bottom: 3em;
}

@media screen and (min-width: 600px) {
  .FAQ-wrap {
    padding: 1em 10em;
  }
}