.Footer {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(255, 255, 255, 0.073);
}

.Footer-links {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  padding-right: 3vw;
}

.Footer-links h3 {
  font-family: 'avenir light', light, sans-serif;
  font-size: 12px;
  color: black;
  padding: 0;
  margin: 0;
}

.Footer-links a {
  font-family: 'avenir', sans-serif;
  font-size: 14px;
  color: black;
  text-decoration: none;
}

.Footer-header {
  margin-right: 1vw;
}

.Footer-link {
  display: flex;
  flex-direction: row;
  justify-items: center;
  margin-left: 5px;
}

.Footer-link-logo {
  height: 20px;
  width: 20px;
  margin-left: 5px;
  margin-right: 5px;
}