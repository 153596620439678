.Flash {
  min-height: 100vh;
  min-width: 100vw;
  background-color: rgb(239, 238, 236);
}

.Flash-h {
  text-align: center;
  font-size: 48px;
  padding-bottom: 0;
}

.Flash-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 40vh;
  max-width: 40vw;
}

@media screen and (min-width: 600px) {
  .Flash-img {
    max-height: 25vh;
    max-width: 25vw;
  }
}

.Flash-body {
  min-height: 50vh;
  margin-top: 5em;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.Flash-link {
  font-family: 'avenir', Helvetica, sans-serif;
  display: flex;
  flex-flow: row wrap;
  /* justify-content: center; */
  align-items: center;
}

.Flash-body a {
  text-decoration: none;
  color: black;
  font-size: 24px;
  padding: 1em 0;
}

.Flash-icon {
  padding-left: 1em;
  height: 28px;
}