.About {
  background-color: rgb(239, 238, 236);
}

.About-body {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  margin: 0 5em;
}

.About-img {
  /* display: block;
  margin-left: auto;
  margin-right: auto; */
  max-height: 80vh;
  max-width: 80vw;
}

.About-blurb {
  /* margin-left: 2em; */
  padding-bottom: 5em;
  font-family: 'avenir', sans-serif;
  text-align: justify;
}

@media screen and (min-width: 600px) {
  .About-img {
    max-width: 35vw;
  }

  .About-blurb {
    margin-left: 2em;
    max-width: 40vw;
  }
}