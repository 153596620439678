.Navbar {
  background-color: transparent;
  padding: 1em;
}

.Navbar-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.Navbar-links a {
  font-family: 'avenir light', "Arial Black", sans-serif;
  color: rgb(32, 32, 32);
  display: flex;
  flex-flow: row wrap;
  font-size: 24px;
  text-align: center;
  text-decoration: none;
}

.Navbar-header {
  font-family: 'avenir black', sans-serif;
  color: black;
}