.Book {
  min-height: 100vh;
  min-width: 100vw;
  background-color: rgb(239, 238, 236);
}

.Book-h {
  text-align: center;
  font-size: 48px;
  padding-bottom: 0;
  font-family: 'avenir', Helvetica, sans-serif;
}

.Book-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 80vh;
  max-width: 80vw;
}

@media screen and (min-width: 600px) {
  .Book-img {
    max-height: 40vh;
    max-width: 40vw;
  }
}

.Book-body {
  min-height: 50vh;
  margin-top: 5em;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.Book-link {
  font-family: 'avenir', Helvetica, sans-serif;
  display: flex;
  flex-flow: row wrap;
  /* justify-content: center; */
  align-items: center;
}

.Book-a {
  text-decoration: none;
  color: black;
  font-size: 24px;
  padding: 1em 0;
}

.Book a {
  text-decoration: none;
  color: black;
}

.Book-icon {
  padding-left: 1em;
  height: 28px;
}